<template>
  <v-data-table
    :headers="headers"
    :items="items"
    class="dt-listing"
    dense
    disable-sort
    hide-default-footer
  ></v-data-table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ReportTableFooter from "@/modules/reports/components/ReportTableFooter.vue";
import { DataTableHeader } from "@/mixins/DataTableMixin";

@Component({
  components: { ReportTableFooter },
})
export default class ReportTable extends Vue {
  @Prop([Array, Object]) readonly items!: Record<string, any>[];
  @Prop([Array, Object]) readonly headers!: DataTableHeader[];
  @Prop(Object) readonly tableOptions!: Record<string, any>;
}
</script>
